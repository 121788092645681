import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom';

const AuthLayouts = () => {
    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}
export default AuthLayouts