import React, { Fragment } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Sidebar from './app-layouts/sidebar';
import { Col, Row } from 'antd';

const AppLayouts = () => {

    const location = useLocation();
    const { pathname } = location;
    return (
        <Fragment>
            <div className='mobile-reponsive'>
            <Sidebar />
            </div>
           
            <Row >
                <Col xs={pathname==="/payslip/payslipview"?0:0} sm={pathname==="/payslip/payslipview"?0:0} md={1} lg={2} xl={2}>
                <Sidebar />
                </Col>
                <Col xs={pathname==="/payslip/payslipview"?24:24} sm={pathname==="/payslip/payslipview"?24:24} md={23} lg={22} xl={22} className='swipe'>
                <Outlet />
                </Col>
            </Row>

        </Fragment>
    )
}

export default AppLayouts