import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Image from 'helpers/Image';
import '../style.css';
import { Popover } from 'antd';


const Sidebar = () => {

    return (
        <Fragment>
            <div className="sidebar" >
                <div className="sidebar-inner">
                    <Image name="logintop.svg" />
                </div>
                <div className="sidebar-menu" >

                    <ul className="sidebar-vertical">
                        <li>
                            <Popover placement="right" content={(
                                <div>
                                    <p>Dashboard</p>
                                </div>
                            )} ><Link to="/dashboard">
                                    {window.location.pathname === "/dashboard" ?
                                        <Image name="HomeActive.svg" /> : <Image name="Home.svg" />}
                                </Link>
                            </Popover>
                        </li>
                        <li ><Popover placement="right" content={(
                                <div>
                                    <p>Salary structure</p>
                                </div>
                            )} >
                            <Link to="/salary">
                                {window.location.pathname === "/salary" ?
                                    <Image name="CaseActive.svg" /> : <Image name="Case.svg" />}
                            </Link>
                            </Popover>
                        </li>
                        <li>
                        <Popover placement="right" content={(
                                <div>
                                    <p>Tax Summary</p>
                                </div>
                            )} >
                            <Link to="/taxsummary">
                                <Image name="Documentw.svg" className={window.location.pathname === "/taxsummary" ? "slidermenuactive" : ""} />
                            </Link>
                            </Popover>
                        </li>
                        <li>
                        <Popover placement="right" content={(
                                <div>
                                    <p>Payslips</p>
                                </div>
                            )} >
                            <Link to="/payslips">
                                <Image name="Folder.svg" className={window.location.pathname === "/payslips" ? "slidermenuactive" : ""} />
                            </Link>
                            </Popover>
                        </li>
                    </ul>

                </div>
                <div className="sidebar-bottom">
                <Link to="/settings">
                    <Image name="avatar.svg" />
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

export default Sidebar;
