import React, { lazy, Suspense } from "react";
import { Routes, Route ,na } from "react-router-dom";
import './App.css';

// Layouts
import AuthLayouts from "layouts/AuthLayouts";
import AppLayouts from "layouts/AppLayouts";

// Auth Routes
const Login = lazy(() => import("./views/auth/login"));
const Register = lazy(() => import("./views/auth/register"));
const ForgotPassword = lazy(() => import("./views/auth/forgot-password"));

// App Routes
const Dashboard = lazy(() => import("./views/app/dashboard"));
const Settings = lazy(() => import("./views/app/settings"));
const Salary = lazy(() => import("./views/app/salary"));
const SalaryStructure = lazy(() => import("./views/app/salary/structure"));
const Payslips = lazy(() => import("./views/app/salary/payslip"));
const PaySlipView = lazy(() => import("./views/app/salary/payslip/common/commonView"));
const AnnualEarning = lazy(() => import("./views/app/salary/annualEarning"));
const Payslip = lazy(() => import("./views/app/payslips"));
const TaxSummary = lazy(() => import("./views/app/tax-summary"));
const EPFSummary = lazy(() => import("./views/app/salary/summary"));

function App() {
  return (
    <Suspense fallback="">
      <Routes>
        <Route element={<AuthLayouts />}>
          <Route exact path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<AppLayouts />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/payslips" element={<Payslip />} />
          <Route path="/taxsummary" element={<TaxSummary />} />
          <Route element={<Salary />} >
          <Route path="/salary" element={<SalaryStructure />} />
          <Route path="/payslip" element={<Payslips />} />
          <Route path="/payslip/payslipview" element={<PaySlipView />} />
          <Route path="/annualearning" element={<AnnualEarning />} />
          <Route path="/epfSummary" element={<EPFSummary />} />
          </Route>
         
        </Route>
      </Routes>
    </Suspense>
  );
}
export default App;
